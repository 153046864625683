<template>
  <v-card class="rd elevation-0">
    <v-img
      class="img"
      :src="src"
      width="281"
      max-height="300"
      @click="imgClick"
      style="cursor: pointer"
    />

    <span class="name">{{ pageValue.name || "No Name" }}</span>
    <span class="num">{{ pageValue.nftCount || 0}} </span>
    <div class="contant ">
      <div class="des">{{pageValue.description}}</div>
      <div class="d-flex flex-row bottom">
         <div class="avatar">
       <Avatar  :size="47" :did="pageValue.owner" :hash="pageValue.creatorPhoto" showAvatarInfo/>
        </div>
        <!-- <v-img
          class="avatar"
          style="border: solid 2px #ffffff; cursor: pointer"
          :src="usersrc"
          @click="tocollectpage"
        /> -->
        <div class="creatname">{{pageValue.creatorName}}</div>
      </div>
    </div>

    <slot></slot>
  </v-card>
</template>

<script>
import { getFileSrc } from "@/utils/file";
const WORK_KEY = "WORK";

const AVATAR_KEY = "AVATAR";
import defaultImg from "@/assets/default.png";
import Avatar from "@/components/avatar/index";

export default {
    components: {	Avatar	},
  props: {
    hash: {
      type: String,
    },
    pageValue: {
      type: Object,
    },
    showAvatar: {
      type: Boolean,
      default: false,
    },
    otherpage: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
    collectfrom: {
      type: String,
    },
    collectsource: {
      type: String,
    },
  },
  data: () => ({
    src: undefined,
    usersrc: "",
    defaultImg,
  }),
  watch: {
    hash: {
      handler: async function (hash) {
       if (!hash) {
          hash = "QmPcXBirGiUSyjb8i2FQUpNDTtPHBsgo7tviwwm4YQeJ6p"
        }
        this.src = await getFileSrc(WORK_KEY, hash);
      },
      immediate: true,
    },
  },
  async mounted() {
    console.log("wxl --- collectsource", this.collectsource);
    if (this.pageValue.creatorPhoto != null) {
      this.usersrc = await getFileSrc(AVATAR_KEY, this.pageValue.creatorPhoto);
    } else {
      this.usersrc = defaultImg;
    }
	if (this.pageValue.creatorName == null) {
	 this.pageValue.creatorName=this.pageValue.owner.substr(0,6)+"..."+this.pageValue.owner.substr(-6)
	}

    if (this.pageValue.listAmount) {
      this.pageValue.amount = this.pageValue.listAmount;
    }
  },
  methods: {
    tocollectpage() {
      this.$router.push({
        name: "Otherpage",
        query: { did: this.pageValue.owner },
      });
    },
    imgClick() {
      let url;
      if (this.pageValue.linkUrl) {
        url = this.pageValue.linkUrl;
      } else {
        url = this.pageValue.address;
      }
      console.log(
        "wxl -- 123455",
        this.type,
        this.pageValue.owner,
        this.$store.state.did
      );
      if (this.type == "createorPage") {

        this.$router.push({
          path: `/collection_create/${url}/createCollect/${this.$store.state.did}`,
        });
      } else {
        this.$router.push({
           path: `/collection/${url}/${this.collectsource}/${this.pageValue.owner}`,  
        });
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.v-card {
  width: 100%;
  height: 300px;
  background-color: #270645;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  background-color: #270645;

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    width: 286px;
  }

  &:hover {
    border: solid 2px #1d42ff;
  }

  .img {
    object-fit: cover;
    opacity: 0.95;
  }

  .name {
    position: absolute;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    width: 150px;
    color: #ffffff;
    top: 18px;
    left: 18px;
    font-weight: bold !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .num {
    position: absolute;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    color: #ffffff;
    top: 42px;
    left: 18px;
  }
  .contant {
    position: absolute;
    bottom: -95px;
    left: 0;
    .des{
      width:280px;
      	font-family: Helvetica;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 24px;
	letter-spacing: 0px;
	color: #270645;
    overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }

    .bottom {
      text-align: center;
      align-content: center;
      margin-top:2px;
      .avatar {
        width: 47px !important;
        height: 47px !important;
        border-radius: 50% !important;
      }
      .creatname {
        width: 220px;
        font-family: Helvetica;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: 0px;
        color: #270645;
        margin-top: 10px;
        margin-left: 10px;
        text-align: left;
           overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      }
    }
  }
}
</style>